<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In stars, helium fuses with carbon to form oxygen. The mass of a
        <chemical-latex content="^{4}He" />
        atom is
        <stemble-latex content="$4.00260324\,\text{amu,}$" />
        the mass of a
        <chemical-latex content="^{12}C" />
        atom is
        <stemble-latex content="$12.00000000\,\text{amu,}$" />
        while the mass of a
        <chemical-latex content="^{16}O" />
        atom is
        <stemble-latex content="$15.99491463\,\text{amu.}$" />
        How much energy is released during this fusion reaction? Note that
        <stemble-latex content="$1\,\text{amu}=1.66094\times 10^{-27}\,\text{kg}$" />
        and
        <stemble-latex content="$\text{c}=2.99792458\times 10^{8}\,\text{m/s.}$" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{E:}$"
        append-text="$\text{J}$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220A4Q4',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
